import { memo } from 'react';
import Loadable from 'react-loadable';
import LoadingRoutes from 'utils/Components/LoadingRoutes';
import { refactorRoute } from './routeConstant';
import { Navigate, redirect } from 'react-router-dom';
import Redirect from './components/Redirect';

const Loading = memo(props => {
    // console.log(props);

    return <LoadingRoutes />;
});
Loading.displayName = 'Loading';

const LoaderBuilder = (loader: () => Promise<any>) =>
    Loadable({ loader, loading: Loading });

const donorDetailRoutes = [
    {
        name: 'Hanh Chinh',
        path: refactorRoute.DONOR_DETAIL_HANH_CHINH,
        component: LoaderBuilder(() => import('view/DonorDetail/HanhChinh')),
    },
    {
        name: 'Chan Doan',
        path: refactorRoute.DONOR_DETAIL_CHAN_DOAN,
        component: LoaderBuilder(() => import('view/DonorDetail/ChanDoan')),
        children: [
            {
                name: 'Default',
                path: refactorRoute.DONOR_DETAIL_CHAN_DOAN,
                component: () => (
                    <Redirect to={refactorRoute.DONOR_DETAIL_CHAN_DOAN_CHUNG} />
                ),
            },
            {
                name: 'Chan Doan Chung',
                path: refactorRoute.DONOR_DETAIL_CHAN_DOAN_CHUNG,
                component: LoaderBuilder(
                    () => import('view/DonorDetail/ChanDoan/ChanDoanChung')
                ),
            },
            {
                name: 'Chan Doan Tu Vong',
                path: refactorRoute.DONOR_DETAIL_CHAN_DOAN_TU_VONG,
                component: LoaderBuilder(
                    () => import('view/DonorDetail/ChanDoan/ChanDoanTuVong')
                ),
            },
        ],
    },
];

export default donorDetailRoutes;
