import { FC, memo, useMemo } from 'react';
import { generatePath, Navigate, useParams } from 'react-router-dom';

const Redirect: FC<{ to: string }> = memo(({ to }) => {
    const params = useParams();
    const path = useMemo(() => generatePath(to, params), [params, to]);

    return <Navigate to={path} replace />;
});

Redirect.displayName = 'Redirect';

export default Redirect;
