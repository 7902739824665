import { memo } from 'react';
import Loadable from 'react-loadable';
import { Navigate } from 'react-router-dom';
import routeConstant, { refactorRoute } from 'route/routeConstant';
import { constant } from 'services/Constant';
import LoadingRoutes from 'utils/Components/LoadingRoutes';
import DeceasedDonorMain from 'view/DonorLayout/DeceasedDonorLayout';
import LivingDonorMain from 'view/DonorLayout/LivingDonorLayout/LivingDonorMain';
import donorDetailRoutes from './DonorRoutes';

const Loading = memo(() => <LoadingRoutes />);
Loading.displayName = 'Loading';

const LoaderBuilder = loader => Loadable({ loader, loading: Loading });

const Login = Loadable({
    loader: () => import('view/Login/Login'),
    loading: Loading,
});

const ResetPassword = Loadable({
    loader: () => import('view/Login/ResetPassword'),
    loading: Loading,
});

const ForgotPassword = Loadable({
    loader: () => import('view/Login/ForgotPassword'),
    loading: Loading,
});

const RecipientLayout = Loadable({
    loader: () => import('view/RecipientLayout'),
    loading: Loading,
});

const DonorLayout = Loadable({
    loader: () => import('view/DonorLayout/DonorLayout'),
    loading: Loading,
});

const DefaultLayout = Loadable({
    loader: () => import('view/DefaultLayout/DefaultLayout'),
    loading: Loading,
});

// Recipient Layout
const RecipientAdministrativeInfo = Loadable({
    loader: () => import('view/RecipientLayout/AdministrativeInfo'),
    loading: Loading,
});

// Default Layout
const DSNguoiNhanPhuHopHienChet = Loadable({
    loader: () => import('view/DonorList/DSNguoiNhanPhuHopHienChet'),
    loading: Loading,
});

const SoSanhSuPhuHop = Loadable({
    loader: () => import('view/DonorList/SoSanhSuPhuHop'),
    loading: Loading,
});

const Receiver = Loadable({
    loader: () => import('view/Receiver/Receiver'),
    loading: Loading,
});

const ReceiverFit = Loadable({
    loader: () => import('view/ReceiverFit/ReceiverFit'),
    loading: Loading,
});

const DanhSachChoGhep = Loadable({
    loader: () => import('view/DanhSachChoGhep/DanhSachChoGhep'),
    loading: Loading,
});

const Account = Loadable({
    loader: () => import('view/AccountFix/Account'),
    loading: Loading,
});

const ChangeProfile = Loadable({
    loader: () => import('view/AccountFix/ChangeProfile'),
    loading: Loading,
});

const ChangePassword = Loadable({
    loader: () => import('view/AccountFix/ChangePassword'),
    loading: Loading,
});

const DonorList = Loadable({
    loader: () => import('view/DonorList/DonorList'),
    loading: Loading,
});

const NguoiHienLoai = Loadable({
    loader: () => import('view/Patients/NguoiHienLoai'),
    loading: Loading,
});

const NguoiNhanLoai = Loadable({
    loader: () => import('view/Patients/NguoiNhanLoai'),
    loading: Loading,
});

// Danh sach cho ghep
const DanhSachChoGhepTableWrapper = Loadable({
    loader: () => import('route/components/DanhSachChoGhepTableWrapper'),
    loading: Loading,
});

const DonorViewPDF = Loadable({
    loader: () => import('view/DonorLayout/ExportPDF'),
    loading: Loading,
});

const LivingDiagnosis = Loadable({
    loader: () => import('view/DonorLayout/LivingDonorLayout/Diagnosis'),
    loading: Loading,
});

const LivingHinhAnhHoc = Loadable({
    loader: () => import('view/DonorLayout/LivingDonorLayout/HinhAnhHoc'),
    loading: Loading,
});

const LivingOrganReview = Loadable({
    loader: () =>
        import('view/DonorLayout/LivingDonorLayout/Conclusion/OrganReview'),
    loading: Loading,
});

const LivingConclusion = Loadable({
    loader: () => import('view/DonorLayout/LivingDonorLayout/Conclusion'),
    loading: Loading,
});

const LivingClinical = Loadable({
    loader: () => import('view/DonorLayout/LivingDonorLayout/Clinical'),
    loading: Loading,
});

const LivingHistory = Loadable({
    loader: () => import('view/DonorLayout/LivingDonorLayout/History'),
    loading: Loading,
});

const LivingSurgery = Loadable({
    loader: () => import('view/DonorLayout/LivingDonorLayout/Surgery'),
    loading: Loading,
});

// // Donor Layout -- Decreased
const DonorFormLayout = Loadable({
    loader: () => import('view/DonorLayout/DeceasedDonorLayout/FormLayout'),
    loading: Loading,
});

const RecipientFormLayout = Loadable({
    loader: () => import('view/RecipientLayout/FormLayout'),
    loading: Loading,
});

const routes = [
    {
        name: 'Log In',
        path: constant.ROUTE_LOGIN,
        component: Login,
    },
    {
        name: 'Reset Password',
        path: constant.ROUTE_RESET_PASSWORD,
        component: ResetPassword,
    },
    {
        name: 'Forgot Password',
        path: constant.ROUTE_FORGOT_PASSWORD,
        component: ForgotPassword,
    },
    {
        name: 'Donor Detail',
        path: refactorRoute.DONOR_DETAIL,
        component: Loadable({
            loader: () => import('view/DonorDetail'),
            loading: Loading,
        }),
        children: donorDetailRoutes,
    },
    {
        name: 'Default Layout',
        path: '/',
        component: DefaultLayout,
        children: [
            {
                name: 'Default Route',
                path: '/',
                component: () => (
                    <Navigate to={refactorRoute.DONOR_REGISTER_LIST} />
                ),
            },
            {
                // TODO: Need to remove this in the future
                name: 'Donors List View',
                path: constant.ROUTE_DONOR_LIST,
                component: DonorList,
            },
            {
                name: 'DSNguoiNhanPhuHopHienChet',
                path: constant.ROUTE_DS_NGUOI_NHAN_PHU_HOP_HIEN_CHET,
                component: DSNguoiNhanPhuHopHienChet,
            },
            {
                name: 'SoSanhSuPhuHop',
                path: constant.ROUTE_COMPARE,
                component: SoSanhSuPhuHop,
            },
            {
                name: 'Receiver',
                path: constant.ROUTE_RECEIVER,
                component: Receiver,
            },
            {
                name: 'ReceiverFit',
                path: constant.ROUTE_RECEIVER_FIT,
                component: ReceiverFit,
            },
            {
                name: 'DanhSachChoGhep',
                path: constant.ROUTE_DANH_SACH_CHO_GHEP,
                component: DanhSachChoGhep,
                children: [
                    {
                        name: 'DanhSachChoGhepTable HienChet',
                        path: constant.ROUTE_CHO_GHEP_TU_HIEN_CHET,
                        component: DanhSachChoGhepTableWrapper,
                    },
                    {
                        name: 'DanhSachChoGhepTable HienSong',
                        path: constant.ROUTE_CHO_GHEP_TU_HIEN_SONG,
                        component: DanhSachChoGhepTableWrapper,
                    },
                    {
                        name: 'DanhSachChoGhepTable Default',
                        path: constant.ROUTE_DANH_SACH_CHO_GHEP,
                        component: () => (
                            <Navigate
                                to={constant.ROUTE_CHO_GHEP_TU_HIEN_CHET}
                            />
                        ),
                    },
                ],
            },
            {
                name: 'Account',
                path: constant.ROUTE_ACCOUNT,
                component: Account,
            },
            {
                name: 'Change Account',
                path: constant.ROUTE_ACCOUNT_CHANGE_PROFILE,
                component: ChangeProfile,
            },
            {
                name: 'Change Password',
                path: constant.ROUTE_ACCOUNT_CHANGE_PASSWORD,
                component: ChangePassword,
            },
            {
                name: 'Người hiến loại',
                path: constant.ROUTE_NGUOI_HIEN_LOAI,
                component: NguoiHienLoai,
            },
            {
                name: 'Người nhận loại',
                path: constant.ROUTE_NGUOI_NHAN_LOAI,
                component: NguoiNhanLoai,
            },

            // Refactored routes
            {
                name: 'Donor Register List', // Danh sach dang ky hien
                path: refactorRoute.DONOR_REGISTER_LIST,
                component: LoaderBuilder(() =>
                    import('view/Donor/Views/Register')
                ),
            },
            {
                name: 'Receiver Register List', // Danh sach dang ky nhan
                path: refactorRoute.RECEIVER_REGISTER_LIST,
                component: LoaderBuilder(() =>
                    import('view/Receiver/Views/Register')
                ),
            },
            {
                name: 'Donor Waiting List', // Danh sach dang ky hien
                path: refactorRoute.DONOR_WAITING_LIST,
                component: LoaderBuilder(() =>
                    import('view/Donor/Views/Waiting')
                ),
            },
            {
                name: 'Receiver Waiting List', // Danh sach nguoi nhan
                path: refactorRoute.RECEIVER_WAITING_LIST,
                component: LoaderBuilder(() =>
                    import('view/Receiver/Views/Waiting')
                ),
            },
            {
                name: 'Donor Donated List', // Danh sach da hien
                path: refactorRoute.DONOR_DONATED_LIST,
                component: LoaderBuilder(() =>
                    import('view/Donor/Views/Donated')
                ),
            },
            {
                name: 'Receiver Received List', // Danh sach nguoi nhan
                path: refactorRoute.RECEIVER_RECEIVED_LIST,
                component: LoaderBuilder(() =>
                    import('view/Receiver/Views/Received')
                ),
            },
            {
                name: 'Donor Rejected List', // Danh sach loai hien
                path: refactorRoute.DONOR_REJECTED_LIST,
                component: LoaderBuilder(() =>
                    import('view/Donor/Views/Rejected')
                ),
            },
            {
                name: 'Receiver Received List', // Danh sach nguoi nhan
                path: refactorRoute.RECEIVER_REJECTED_LIST,
                component: LoaderBuilder(() =>
                    import('view/Receiver/Views/Rejected')
                ),
            },
        ],
    },
    {
        name: 'Donor Layout',
        path: constant.ROUTE_DONOR,
        component: DonorLayout,
        children: [
            {
                name: 'Living Donor',
                path: routeConstant.ROUTE_LIVING_DONOR,
                component: LivingDonorMain,
                children: [
                    {
                        name: 'LivingDiagnosis',
                        path: routeConstant.ROUTE_LIVING_DONOR__DIAGNOSIS,
                        component: LivingDiagnosis,
                    },
                    {
                        name: 'LivingHinhAnhHoc1',
                        path: routeConstant.ROUTE_LIVING_DONOR__HINH_ANH_HOC,
                        component: LivingHinhAnhHoc,
                    },
                    {
                        name: 'LivingHinhAnhHoc2',
                        path: routeConstant.ROUTE_LIVING_DONOR__MOLECULAR_BIOLOGY,
                        component: LivingHinhAnhHoc,
                    },
                    {
                        name: 'LivingHinhAnhHoc3',
                        path: routeConstant.ROUTE_LIVING_DONOR__HUYET_HOC_TRUYEN_MAU,
                        component: LivingHinhAnhHoc,
                    },
                    {
                        name: 'LivingHinhAnhHoc4',
                        path: routeConstant.ROUTE_LIVING_DONOR__HOA_SINH,
                        component: LivingHinhAnhHoc,
                    },
                    {
                        name: 'LivingHinhAnhHoc5',
                        path: routeConstant.ROUTE_LIVING_DONOR__VI_SINH,
                        component: LivingHinhAnhHoc,
                    },
                    {
                        name: 'LivingHinhAnhHoc6',
                        path: routeConstant.ROUTE_LIVING_DONOR__PATHOLOGY,
                        component: LivingHinhAnhHoc,
                    },
                    {
                        name: 'LivingOrganReview',
                        path: routeConstant.ROUTE_LIVING_DONOR__CONCLUSION__ORGAN,
                        component: LivingOrganReview,
                    },
                    {
                        name: 'LivingConclusion',
                        path: routeConstant.ROUTE_LIVING_DONOR__CONCLUSION,
                        component: LivingConclusion,
                    },
                    {
                        name: 'LivingClinical',
                        path: routeConstant.ROUTE_LIVING_DONOR__CLINICAL,
                        component: LivingClinical,
                    },
                    {
                        name: 'LivingHistory',
                        path: routeConstant.ROUTE_LIVING_DONOR__HISTORY,
                        component: LivingHistory,
                    },
                    {
                        name: 'LivingSurgery',
                        path: routeConstant.ROUTE_LIVING_DONOR__SURGERY,
                        component: LivingSurgery,
                    },
                ],
            },
            {
                name: 'Pass Donor',
                path: routeConstant.ROUTE_PASS_DONOR,
                component: DeceasedDonorMain,
                children: [
                    {
                        name: 'DonorFormLayout',
                        path: routeConstant.ROUTE_PASS_DONOR__FORM_LAYOUT,
                        component: DonorFormLayout,
                    },
                ],
            },
        ],
    },
    {
        name: 'Recipient Layout',
        path: constant.ROUTE_RECIPIENT,
        component: RecipientLayout,
        children: [
            {
                name: 'AdministrativeInfo',
                path: routeConstant.ROUTE_RECIPIENT__ADMINISTRATIVE,
                component: RecipientAdministrativeInfo,
            },
            {
                name: 'DonorFormLayout',
                path: routeConstant.ROUTE_RECIPIENT__FORM_LAYOUT,
                component: RecipientFormLayout,
            },
        ],
    },
    {
        name: 'Donor View PDF',
        path: routeConstant.ROUTE_DONOR__VIEW_PDF,
        component: DonorViewPDF,
    },
];

export default routes;
